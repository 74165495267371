import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: "VIP Club",
    brand: "Dime Industries",
  },
  getters: {
    getBrand(state) {
      return state.brand;
    },
    getAppName(state) {
      return state.appName;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
