import Vue from "vue";
import { colors } from "vuetify/lib";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        bg: "#1F7087",
        success: colors.green.base,
        next: colors.blue.base,
      },
      dark: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        bg: "#1F7087",
        success: colors.green.base,
        next: colors.blue.base,
      },
    },
  },
  icons: {
    iconfont: "fa",
    values: {
      down: "mdi-chevron-down",
      up: "mdi-chevron-up",
    },
  },
});
