<template>
  <v-app>
    <v-main style="background: #1f7087; background-color: #1f7087">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* @font-face {
  font-family: 'Montserrat', sans-serif;
} */

@font-face {
  font-family: "GothamBook";
  src: local("Gotham"), url("@/assets/fonts/GothamBook.ttf") format("truetype");
}

@font-face {
  font-family: "GothamMed";
  src: local("Gotham"),
    url("@/assets/fonts/GothamMedium.ttf") format("truetype");
}

@font-face {
  font-family: "GothamThin";
  src: local("Gotham"), url("@/assets/fonts/GothamThin.otf") format("opentype");
}

.gothamBook {
  font-family: "Montserrat";
}

.gothamThin {
  font-family: "Montserrat";
}

.gothamMed {
  font-family: "Montserrat";
}
</style>
